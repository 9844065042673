var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.item)?_c('v-card',{staticClass:"card-outer",staticStyle:{"border-radius":"12px !important","word-break":"break-word"},attrs:{"flat":"","ripple":false,"height":_vm.$vuetify.breakpoint.xs ? '125' : '150'},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('carousel-clicked')}}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"shrink"},[_c('v-img',{staticClass:"image-bg",staticStyle:{"border-radius":"12px !important"},attrs:{"eager":"","width":"120","height":"124","src":_vm.item.thumbnail && _vm.item.thumbnail.url
            ? _vm.item.thumbnail.url
            : _vm.affiliateImage}}),(_vm.item.new && !_vm.item.insider)?_c('div',{staticStyle:{"position":"absolute","top":"0px","left":"16px"}},[_c('v-img',{attrs:{"src":require('@/assets/flag.png')}}),_c('span',{staticClass:"flag-content-new Text-White-Text-4---12-Left-Align"},[_vm._v(_vm._s(_vm.$t("dashboard.new")))])],1):_vm._e(),(_vm.item.insider)?_c('div',{staticStyle:{"position":"absolute","top":"0px","left":"16px"}},[_c('v-img',{attrs:{"src":require('@/assets/flag.png')}}),_c('span',{staticClass:"Text-White-Text-4---12-Left-Align flag-content-insider"},[_vm._v(_vm._s(_vm.$t("dashboard.insider")))])],1):_vm._e()],1),_c('v-col',{staticClass:"grow"},[_c('v-toolbar',{staticClass:"pa-0 ma-0",staticStyle:{"border-radius":"12px 12px 0 0 !important","background":"transparent"},attrs:{"height":"48px","flat":""}},[(_vm.item.category)?[(_vm.item.category.pictogram)?[_c('div',{staticClass:"mr-2 gradient pa-2 rounded-circle"},[_c('v-img',{staticClass:"filter",attrs:{"width":"15px","height":"auto","src":_vm.item.category.pictogram}})],1)]:_vm._e(),_c('span',{staticClass:"mt-1 Text-Dark-Text-3---14-Left-Align",class:!_vm.item.category.pictogram ? 'ml-2' : '',style:(!_vm.$store.state.settings.dark ? 'color: #606060;' : '')},[_vm._v(" "+_vm._s(_vm.$t(("categories." + (_vm.item.category.title))))+" ")])]:_vm._e(),_c('v-spacer'),(_vm.item.userRating && _vm.item.userRating > 0)?[_c('v-icon',{attrs:{"color":"yellow darken-2"}},[_vm._v(" mdi-star ")]),_c('span',{staticClass:"caption Text-Dark-Text-4---12-Center"},[_vm._v(_vm._s(parseFloat(_vm.item.userRating).toFixed(1)))])]:_vm._e(),(
            _vm.$store.state.profile.data &&
            _vm.$store.state.session.user &&
            !_vm.$store.state.isMiKY
          )?_c('v-btn',{attrs:{"icon":"","large":""},on:{"click":function($event){$event.stopPropagation();return _vm.toggleFavorite(_vm.item.id)}}},[_c('v-icon',{attrs:{"color":_vm.find(_vm.$store.state.profile.data.favorites, function (fav) {
                return fav === _vm.item.id;
              })
                ? 'red'
                : '#475960'}},[_vm._v(_vm._s(_vm.find(_vm.$store.state.profile.data.favorites, function (fav) { return fav === _vm.item.id; }) ? "mdi-heart" : "mdi-heart-outline"))])],1):_vm._e()],2),_c('v-container',{staticClass:"py-0"},[_c('div',{directives:[{name:"line-clamp",rawName:"v-line-clamp",value:(1),expression:"1"}],staticClass:"Heading-Dark-H4-18-Left-Align"},[_vm._v(" "+_vm._s(_vm.item.header)+" ")]),(_vm.item.subHeader)?_c('div',{directives:[{name:"line-clamp",rawName:"v-line-clamp",value:(1),expression:"1"}],staticClass:"Text-Dark-Text-3---14-Left-Align"},[_vm._v(" "+_vm._s(_vm.item.subHeader)+" ")]):_vm._e(),_c('v-card-actions',{staticClass:"pa-0 card-actions",class:!_vm.item.subHeader ? 'mt-5' : ''},[(_vm.openingTimes)?_c('div',{staticClass:"v-html Text-Dark-Text-4---12-Left-Align",staticStyle:{"word-break":"break-word","width":"55%"},domProps:{"innerHTML":_vm._s(_vm.openingTimes)}}):_c('v-spacer'),_c('div',{staticStyle:{"text-align":"right","width":"45%"}},[(_vm.distance)?[_c('span',{staticClass:"Text-Dark-Text-4---12-Right-Align"},[_vm._v(_vm._s(_vm.distance)+" km")]),_c('v-img',{staticClass:"d-inline-block",class:_vm.$store.state.settings.dark ? 'filter' : '',attrs:{"height":"auto","width":"20","src":require('@/assets/distance.svg')}})]:_vm._e(),(_vm.item.priceSegment)?_c('span',{staticClass:"mb-0",staticStyle:{"font-size":"12px"}},[_vm._v(" "+_vm._s(_vm.item.priceSegment)+" ")]):_vm._e()],2)],1)],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }