<template>
  <!-- max-width: 100% necessary to stretch out map on iPad pro! -->
  <v-container class="pa-0">
    <div class="px-3">
      <back-toolbar title="navigation.map"></back-toolbar>
      <v-row class="mb-2" align="center" justify="center">
        <v-col cols="10">
          <div style="width: 100%" class="px-2">
            {{ $t("dashboard.radius") }}
          </div>
          <v-slider
            v-model="slider"
            class="map-slider"
            dense
            min="1"
            max="100"
            hide-details
            :thumb-size="20"
            thumb-label="always"
            @end="initData()"
            track-color="grey lighten-3"
            :disabled="loading"
          >
            <template v-slot:thumb-label="item">{{ item.value }} Km</template>
          </v-slider>
        </v-col>
        <v-col cols="2">
          <v-btn
            @click="filterDialog = true"
            class="d-inline-block mt-4"
            height="38px"
            icon
            elevation="3"
            style="border-radius: 10px"
            :style="
              $store.state.settings.dark ? 'background-color: #272727;' : ''
            "
            ><v-img
              max-width="25px"
              :src="
                !$store.state.settings.dark
                  ? require('@/assets/img/filter_horizontal.svg')
                  : require('@/assets/img/filter_horizontal_white.svg')
              "
          /></v-btn>
        </v-col>
      </v-row>
      <v-bottom-sheet v-model="filterDialog" @blur="clearFilters()">
        <v-sheet
          class="text-center"
          height="85vh"
          style="border-radius: 30px 30px 0px 0px; z-index: 200"
        >
          <v-toolbar style="border-radius: 30px 30px 0px 0px" flat>
            <v-btn icon @click="clearFilters(), (filterDialog = false)"
              ><v-icon> mdi-close </v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <div class="CAPS-HEADING-DARK-CAPS2-12-CENTER text-uppercase">
              {{ $t("search.filters") }}
            </div>
            <v-spacer></v-spacer>
            <v-btn
              text
              small
              @click="clearFilters()"
              class="CAPS-HEADING-DARK-CAPS2-12-CENTER text-uppercase"
              >{{ $t("search.clear") }}</v-btn
            >
          </v-toolbar>
          <v-container class="py-0 px-5">
            <v-row>
              <v-col cols="12">
                <div class="Text-Dark-Text-2---16-Left-Align py-3">
                  {{ $t("search.selectTags") }}
                </div>
                <div class="Heading-Dark-H4-18-Left-Align py-1">
                  {{ $t("dashboard.categories") }}
                </div>
              </v-col>
            </v-row>
          </v-container>
          <v-container class="filter-items py-0 px-5">
            <v-row>
              <v-col cols="12">
                <v-btn
                  v-for="category in categories"
                  :key="category.title"
                  class="filter-categories Heading-White-H6-14-Center"
                  :ripple="false"
                  :class="category.selected ? 'white--text' : ''"
                  @click="category.selected = !category.selected"
                  :style="
                    category.selected
                      ? { 'background-color': `${category.color}` }
                      : !$store.state.settings.dark
                      ? 'background-color:#fff; color: #000;'
                      : ''
                  "
                  >{{ $t(`categories.${category.title}`) }}</v-btn
                >
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <div class="Heading-Dark-H4-18-Left-Align py-4">Moods</div>
                <v-btn
                  v-for="mood in moods"
                  :key="mood.title"
                  class="filter-categories Heading-White-H6-14-Center"
                  :ripple="false"
                  @click="mood.selected = !mood.selected"
                  :style="
                    mood.selected
                      ? 'background-color: #86d0da'
                      : !$store.state.settings.dark
                      ? 'background-color:#fff; color: #000;'
                      : ''
                  "
                  >{{ $t(`moods.${mood.title}`) }}</v-btn
                >
              </v-col>
            </v-row>
          </v-container>

          <div style="position: sticky; bottom: 12px">
            <v-btn
              min-width="150"
              height="48"
              color="primary"
              class="Heading-White-H4-18-Center mt-5 gradient"
              depressed
              @click="filterDialog = false"
            >
              {{ $t("search.startSearch") }}
            </v-btn>
          </div>
          <v-spacer></v-spacer>
        </v-sheet>
      </v-bottom-sheet>
    </div>
    <template v-if="marker && marker.length > 0">
      <l-map
        ref="map"
        class="map-style"
        :zoom="zoom"
        :center="center"
        @update:zoom="zoomUpdated"
        :options="{ zoomControl: false }"
        @update:center="centerUpdated"
        @update:bounds="boundsUpdated"
        style="z-index: 105"
      >
        <l-tile-layer :url="url"></l-tile-layer>
        <!--<v-tilelayer-googlemutant
        :apikey="apiKey"
        lang="de"
        region="AT"
        :options="options"
      ></v-tilelayer-googlemutant>-->
        <l-control
          @click.native="center = userPosition"
          position="topright"
          class="center-location"
          ><v-btn icon width="30px" height="30px" style="z-index: 100">
            <img
              class="color-filter"
              :src="require('@/assets/img/maparrow.svg')"
            />
          </v-btn>
        </l-control>
        <!-- User position -->
        <l-marker v-if="!loading" :lat-lng="userPosition">
          <l-icon
            :icon-size="[20, 20]"
            :icon-anchor="[10, 10]"
            :icon-url="
              require('@/assets/googlemaps_markers/userPositionCircle.svg')
            "
          ></l-icon>
        </l-marker>

        <l-marker
          v-for="(markedLocation, index) in marker"
          :key="`marker-${index}`"
          :lat-lng="markedLocation.location"
          @click="markerClicked(markedLocation)"
        >
          <l-icon
            :icon-size="markedLocation.selected ? [50, 50] : [30, 30]"
            :icon-anchor="markedLocation.selected ? [13.5, 47.25] : [10, 35]"
            :class-name="markedLocation.selected ? 'highlight-pin' : ''"
            :icon-url="getCategoryIcon(markedLocation)"
          ></l-icon>
        </l-marker>
      </l-map>
      <v-container
        v-if="marker.length > 0"
        class="pr-0"
        style="position: absolute; bottom: 0; z-index: 110"
      >
        <vue-glide
          v-model="sliderModel"
          type="carousel"
          :options="optionsSlider"
          class="pb-3"
        >
          <vue-glide-slide
            v-for="(markedLocation, i) in marker"
            :key="'carousel-' + i"
          >
            <map-slider-recommendation
              @carousel-clicked="carouselClicked(i)"
              :item="markedLocation"
            ></map-slider-recommendation>
          </vue-glide-slide>
        </vue-glide>
      </v-container>
    </template>
    <error-image
      v-else-if="$store.state.map.status != 'fetching'"
      height="325"
      :text="$t('search.noResults')"
      imageName="noResults.png"
    ></error-image>
  </v-container>
</template>

<script>
const safeAreaInsets = require("safe-area-insets");
import find from "lodash/find";
import indexOf from "lodash/indexOf";
import { LMap, LTileLayer, LControl, LMarker, LIcon } from "vue2-leaflet";
import Vue2LeafletGoogleMutant from "vue2-leaflet-googlemutant";
import { LightenDarkenColor } from "lighten-darken-color";
import { mapState, mapMutations, mapActions } from "vuex";
//V2.0
import ErrorImage from "@/components/app/common/ErrorImage";
import MapSliderRecommendation from "@/components/map/MapSliderRecommendation";
import BackToolbar from "@/components/app/common/BackToolbar";
import filter from "lodash/filter";
export default {
  components: {
    LMap,
    LControl,
    LMarker,
    LIcon,
    LTileLayer,
    BackToolbar,
    MapSliderRecommendation,
    ErrorImage,
  },
  data: () => {
    return {
      carousel: undefined,
      url: "https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png",
      bounds: null,
      apiKey: "AIzaSyDg1Dztn_OVruFwNPvUPWG2p3OGBBVRp2M",
      options: {
        type: "roadmap",
        zoomControl: "false",
      },
      value: "km",
      fullscreen: false,
      filterDialog: false,
      priceIndicator: "$$$$",
      sliderModel: null,
      optionsSlider: {
        perView: 2.1,
        breakpoints: {
          1350: {
            perView: 1.7,
          },
          1000: {
            perView: 1.1,
          },
        },
      },
    };
  },
  computed: {
    ...mapState({
      mapCenter: (state) => state.map.center,
      userPosition: (state) => state.map.userPosition,
    }),
    categories() {
      return this.$store.state.categories.categories;
    },
    moods() {
      return this.$store.state.moods.moods;
    },
    marker() {
      let items = this.$store.state.map.marker;
      if (filter(this.categories, { selected: true }).length > 0) {
        let selectedCategories = filter(this.categories, { selected: true });
        items = this.$store.state.map.marker.filter((marker) => {
          return find(selectedCategories, (category) => {
            return category.id === marker.category.id;
          });
        });
      }
      return items;
    },
    center: {
      get() {
        return this.mapCenter;
      },
      set(val) {
        this.setCenter(val);
      },
    },
    slider: {
      get() {
        return this.$store.state.recommendations.radius;
      },
      set(val) {
        this.$store.commit("recommendations/SET_RADIUS", val);
      },
    },
    zoom: {
      get() {
        return this.$store.state.map.zoom;
      },
      set(val) {
        this.$store.commit("map/SET_ZOOM", val);
      },
    },
    loading() {
      return this.$store.state.map.status === "fetching";
    },
    currentSlide() {
      return this.marker[this.sliderModel] || "";
    },
    currentIndex() {
      return this.marker.findIndex((x) => x.id == this.currentSlide.id);
    },
  },
  methods: {
    initData() {
      this.$store.dispatch("map/nearbyMarker", {});
    },
    removeSelected() {
      this.marker.forEach(function (arrayItem) {
        arrayItem.selected = false;
      });
    },
    zoomUpdated(zoom) {
      this.zoom = zoom;
    },
    boundsUpdated(bounds) {
      this.bounds = bounds;
    },
    centerUpdated(center) {
      this.center = center;
    },
    ...mapMutations({
      setCenter: "map/SET_CENTER",
    }),
    getCategoryIcon(item) {
      if (item.category) {
        if (item.selected) {
          try {
            return require(`@/assets/map/selected_${item.category.id}.svg`);
          } catch (e) {
            return;
          }
        } else {
          try {
            return require(`@/assets/map/${item.category.id}.svg`);
          } catch (e) {
            return;
          }
        }
      } else {
        return void 0;
      }
    },
    clearFilters() {
      this.categories.forEach((cat) => {
        cat.selected = false;
      });
      this.moods.forEach((mood) => {
        mood.selected = false;
      });
    },
    //needed for native build
    openPage(link) {
      if (
        this.$store.state.isMiKY &&
        (navigator.platform === "iPhone" || navigator.platform === "MacIntel")
      ) {
        window.location.assign(link);
      } else {
        window.open(link, "_system");
      }
    },
    find,
    carouselClicked(location) {
      this.$router.safePush({
        name: "detail",
        params: {
          id: this.marker[location].id,
        },
      });
    },
    markerClicked(marker) {
      if (marker.selected) {
        this.$router.safePush({
          name: "detail",
          params: {
            id: marker.id,
          },
        });
      } else {
        this.removeSelected(),
          (this.center = marker.location),
          (marker.selected = true);
        this.sliderModel = this.marker.findIndex((el) => el.selected);
      }
    },
  },
  mounted() {
    /* fix map height on iphone x and similar devices that have safe area insets when carousel is present */
    if (safeAreaInsets.bottom > 0) {
      const wrapper = document.querySelector(".v-content");
      if (wrapper)
        wrapper.style.padding =
          "62px 0px calc(62px + " + safeAreaInsets.bottom + "px)";
    }
    // highlight selected marker and center item in slider when returning to map view
    this.sliderModel = this.marker.findIndex((el) => el.selected);
  },
  watch: {
    // center item in the slider and in the map when using slider functionality
    currentIndex(val) {
      this.removeSelected();
      this.marker[val].selected = true;
      this.setCenter(this.marker[val].location);
      this.sliderModel = this.marker.findIndex((el) => el.selected);
    },
  },
};
</script>

<style lang="scss">
/* Highlight selected divIcon */
.highlight-pin {
  z-index: 1000 !important;
}
.center-location {
  width: 32px;
  height: 35px;
  left: 1px;
  line-height: 30px;
  background: #fff;
  border: 2px solid #aaa;
  border-radius: 4px;
  text-align: center;
}
p {
  span {
    color: inherit !important;
    background-color: transparent !important;
  }
}
.v-card {
  border-radius: 0px !important;
}

.v-input--checkbox .v-label {
  font-size: 12px;
}
.v-input--checkbox .v-messages {
  display: none;
}
.small-text {
  font-size: 0.7em;
}

.leaflet-right.leaflet-bottom {
  .leaflet-control {
    display: none !important;
  }
}
.filter-categories {
  border-radius: 12px;
  display: inline-block;
  margin-bottom: 15px;
  margin-right: 8px;
}
.filter-items {
  max-height: 55vh !important;
  overflow: auto !important;
}
@media (min-height: 751px) {
  .map-style {
    height: 76vh !important;
  }
}
@media (max-height: 750px) {
  .map-style {
    height: 70vh !important;
  }
}
@media (max-height: 650px) {
  .filter-items {
    max-height: 44vh !important;
    overflow: auto !important;
  }
  .map-style {
    height: 70vh !important;
  }
}
@media (max-height: 600px) {
  .filter-items {
    max-height: 40vh !important;
    overflow: auto !important;
  }
  .map-style {
    height: 65vh !important;
  }
}
.leaflet-control-container .center-location.leaflet-control {
  background: none !important;
  border: none !important;
}
.glide__slide {
  margin-left: -20px;
}
</style>
<style>
.leaflet-marker-icon {
  filter: hue-rotate(var(--hue-rotate)) saturate(var(--saturate))
    brightness(var(--brightness));
}
.highlight-pin {
  filter: none;
}
</style>
