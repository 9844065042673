<template>
  <v-card
    v-if="item"
    flat
    style="border-radius: 12px !important; word-break: break-word"
    :ripple="false"
    @click.stop="$emit('carousel-clicked')"
    class="card-outer"
    :height="$vuetify.breakpoint.xs ? '125' : '150'"
  >
    <v-row no-gutters>
      <v-col class="shrink">
        <v-img
          eager
          class="image-bg"
          width="120"
          height="124"
          style="border-radius: 12px !important"
          :src="
            item.thumbnail && item.thumbnail.url
              ? item.thumbnail.url
              : affiliateImage
          "
        >
        </v-img>
        <div
          v-if="item.new && !item.insider"
          style="position: absolute; top: 0px; left: 16px"
        >
          <v-img :src="require('@/assets/flag.png')" />
          <span class="flag-content-new Text-White-Text-4---12-Left-Align">{{
            $t("dashboard.new")
          }}</span>
        </div>
        <div
          v-if="item.insider"
          style="position: absolute; top: 0px; left: 16px"
        >
          <v-img :src="require('@/assets/flag.png')" />
          <span
            class="Text-White-Text-4---12-Left-Align flag-content-insider"
            >{{ $t("dashboard.insider") }}</span
          >
        </div>
      </v-col>
      <v-col class="grow">
        <v-toolbar
          height="48px"
          flat
          class="pa-0 ma-0"
          style="
            border-radius: 12px 12px 0 0 !important;
            background: transparent;
          "
        >
          <template v-if="item.category">
            <template v-if="item.category.pictogram">
              <div class="mr-2 gradient pa-2 rounded-circle">
                <v-img
                  class="filter"
                  width="15px"
                  height="auto"
                  :src="item.category.pictogram"
                />
              </div>
            </template>
            <span
              :class="!item.category.pictogram ? 'ml-2' : ''"
              class="mt-1 Text-Dark-Text-3---14-Left-Align"
              :style="!$store.state.settings.dark ? 'color: #606060;' : ''"
            >
              {{ $t(`categories.${item.category.title}`) }}
            </span>
          </template>
          <v-spacer></v-spacer>
          <template v-if="item.userRating && item.userRating > 0">
            <v-icon color="yellow darken-2"> mdi-star </v-icon>

            <span class="caption Text-Dark-Text-4---12-Center">{{
              parseFloat(item.userRating).toFixed(1)
            }}</span>
          </template>

          <v-btn
            icon
            large
            @click.stop="toggleFavorite(item.id)"
            v-if="
              $store.state.profile.data &&
              $store.state.session.user &&
              !$store.state.isMiKY
            "
            ><v-icon
              :color="
                find($store.state.profile.data.favorites, (fav) => {
                  return fav === item.id;
                })
                  ? 'red'
                  : '#475960'
              "
              >{{
                find($store.state.profile.data.favorites, (fav) => {
                  return fav === item.id;
                })
                  ? "mdi-heart"
                  : "mdi-heart-outline"
              }}</v-icon
            ></v-btn
          >
        </v-toolbar>
        <v-container class="py-0">
          <div v-line-clamp="1" class="Heading-Dark-H4-18-Left-Align">
            {{ item.header }}
          </div>
          <div
            class="Text-Dark-Text-3---14-Left-Align"
            v-line-clamp="1"
            v-if="item.subHeader"
          >
            {{ item.subHeader }}
          </div>
          <v-card-actions
            class="pa-0 card-actions"
            :class="!item.subHeader ? 'mt-5' : ''"
          >
            <div
              v-if="openingTimes"
              v-html="openingTimes"
              class="v-html Text-Dark-Text-4---12-Left-Align"
              style="word-break: break-word; width: 55%"
            ></div>
            <v-spacer v-else></v-spacer>
            <div style="text-align: right; width: 45%">
              <template v-if="distance">
                <span class="Text-Dark-Text-4---12-Right-Align"
                  >{{ distance }} km</span
                >
                <v-img
                  class="d-inline-block"
                  :class="$store.state.settings.dark ? 'filter' : ''"
                  height="auto"
                  width="20"
                  :src="require('@/assets/distance.svg')"
                />
              </template>
              <span
                v-if="item.priceSegment"
                class="mb-0"
                style="font-size: 12px"
              >
                {{ item.priceSegment }}
              </span>
            </div>
          </v-card-actions>
        </v-container>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import find from "lodash/find";
import { mapActions } from "vuex";
import api from "@/api";

export default {
  data() {
    return {
      priceIndicator: "$$$$",
    };
  },
  props: {
    item: Object,
    default: () => {
      return {};
    },
    carousel: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    affiliateImage() {
      const imageUrl = this.item.affiliateImage;
      if (!imageUrl) {
        return require("../../assets/image_placeholder_small.png");
      }
      return imageUrl;
    },
    openingHours() {
      return api.getOpeningHours(this.item);
    },
    distance() {
      try {
        return api.getDistanceToLocation(this.item);
      } catch (error) {
        console.error("Failed to get distance");
        return "0";
      }
    },
    openingTimes() {
      return api.getOpeningHours(this.item);
    },
  },
  methods: {
    ...mapActions("favorites", ["toggleFavorite"]),
    find,
  },
};
</script>

<style scoped>
.v-btn:focus:before,
.v-btn:hover:before {
  opacity: 0 !important;
}
.small-text {
  font-size: 0.7em;
}
.subHeader,
.header {
  white-space: nowrap;
  word-break: normal;
  overflow: hidden;
  text-overflow: ellipsis;
}
.flag-content-new {
  position: absolute;
  bottom: 0;
  left: 35px;
  top: 3px;
  color: #fff;
  text-transform: uppercase;
}
.flag-content-insider {
  position: absolute;
  color: #fff;
  top: 0;
  text-transform: uppercase;
  left: 18px;
  top: 3px;
}
.card-outer {
  position: relative;
  padding-bottom: 0px;
}
/deep/ .v-toolbar .v-toolbar__content {
  padding-left: 8px !important;
  padding-right: 8px !important;
}
/deep/ .v-card--link:focus:before {
  opacity: 0;
}
</style>
